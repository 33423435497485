.main{
    background-image: url('./Shiny\ Overlay.svg');
    background-size:cover;
    width: 100vw;
    height: 100vh;
}

.yellowColor{
    color: #E38B29;
  }
  

.formSection{
    border-radius: 25px;
    min-height: 475px;
    overflow: hidden;

  
}

.myButt{
    border-radius: 25px;
    background-color: #E38B29;
    color: aliceblue;
    border: none;
    
}

@media only screen and (max-width: 767px) {
    .imgFitLogin{
        display: none
    }
}


/*copied ccs for input*/
