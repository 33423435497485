.logoutSVG{
  max-width: 20px;
  max-height: 20px;
  fill: black;
}
.logoutButt{
  border-radius: 20px;

}

.yellowColor{
  color: #E38B29;
}

.mainColor{
  background-color: #E38B29;
  color: #202124;
}

.mainInput{
  width: 100%;
}

/*copied css*/
.search-container {
  width: 80%;
}

.search-container form {
  display: flex;
  align-items: center;
}

.search-container input[type="text"] {
  padding: 12px 20px;
  margin-left: 8px;
  border: 1px solid #ddd;
  border-radius: 25px;
  width: 100%;
}

.search-container button {
  padding: 12px 20px;
  margin-left: 10px;
  background: #E38B29;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}
.search-container button[type="submit"]:hover {
  background: #E38B29;
}

